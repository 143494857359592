import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from 'layouts/zh'
import Seo from 'components/seo'
import * as styles from './index.module.less'

const Sitemap: React.FC = (props) => {
  const {
    data: {
      latestArticleYaml: {
        latestArticle,
        // industryReport,classifiedCatalogue
      },
      industryReportYaml: { industryReport },
      classifiedCatalogueYaml: {
        classifiedCatalogue: { products, solutions, resources, help, dataDriven, learnMore, trust },
      },
    },
  } = props

  const TITLE_MAP = {
    latestArticle: '最新文章',
    industryReport: '行业报告',
    classifiedCatalogue: '分类目录',
  }
  const catalogue = [
    { title: '产品体系', list: products },
    { title: '解决方案', list: solutions },
    { title: '资源中心', list: resources },
    { title: '帮助中心', list: help },
    { title: '数据驱动大会', list: dataDriven },
    { title: '关于神策', list: learnMore },
    { title: '信任中心', list: trust },
  ]
  
  const ItemLayout = (title, dataList) => {
    return (
      <div className={styles.list}>
        <h3>{title}</h3>
        <ul>
          {dataList.map((dataitem) => (
            <li>
              <a href={dataitem.url}>{`${dataitem.title}`}</a>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const catalogueRender = (title, dataList) => {
    return (
      <div className={styles.list}>
        <h3>{title}</h3>
          {dataList.map((group) => (
            <div>
              <h4>{group.title}</h4>
              <ul>
                {group.list?.map((item)=>{
                  return <li><a href={item.url}>{`${item.title}`}</a></li>
                })}
              </ul>
            </div>
          ))}
      </div>
    )
  }

  return (
    <Layout
      header={<></>}
      hideFooter={true}
      showFloat={false}
      showCookieAuth={false}
      showPhoneCall={false}
      showBackTop={false}
    >
      <Seo
        title="网站地图 - 神策数据"
        description="神策数据网站地图收录全站精华内容，不仅包括神策分析云、神策营销云、神策数据根基平台等产品矩阵入口，同时还提供各行业企业数字化解决方案、行业白皮书报告以及企业数字化转型热点资讯。"
        keywords="网站地图,站点地图,sitemap,神策数据"
        saTitle=""
      />
      {/* <div className={styles.list}> */}
      {latestArticle && ItemLayout(TITLE_MAP['latestArticle'], latestArticle)}
      {industryReport && ItemLayout(TITLE_MAP['industryReport'], industryReport)}
      {catalogue && catalogueRender(TITLE_MAP['classifiedCatalogue'], catalogue)}
    </Layout>
  )
}

export const query = graphql`
  query {
    latestArticleYaml {
      latestArticle {
        title
        url
      }
    }
    industryReportYaml {
      industryReport {
        title
        url
      }
    }
    classifiedCatalogueYaml {
      classifiedCatalogue {
        products {
          title
          url
        }
        solutions {
          title
          url
        }
        resources {
          title
          url
        }
        help {
          title
          url
        }
        dataDriven {
          title
          url
        }
        learnMore {
          title
          url
        }
        trust {
          title
          url
        }
      }
    }
  }
`

export default Sitemap
